import React from "react";
import Select from "react-select";

import useFetchRequest from "../shared/useFetchRequest";

export interface AccountPromotion {
  value: number;
  label: string;
}

const PromoEditor: React.FC<{
  applyPromoUrl: string;
  csrfToken: string;
  availablePromos: any[];
  appliedPromos: AccountPromotion[];
}> = ({ applyPromoUrl, csrfToken, availablePromos, appliedPromos }) => {
  const isDisabled = appliedPromos.length > 0;

  const { postRequest } = useFetchRequest(csrfToken);
  const placeholder = isDisabled ? (
    <DisabledPlaceholder />
  ) : (
    <Placeholder numPromosAvailable={availablePromos.length} />
  );

  const onChange = (promo: HTMLSelectElement) => {
    postRequest(applyPromoUrl, { account_promotion_id: promo.value })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error applying promo:", error);
      });
  };

  return (
    <Select
      placeholder={placeholder}
      options={availablePromos}
      onChange={onChange}
      isSearchable
      classNamePrefix={"promo-editor"}
      isDisabled={isDisabled}
      aria-labelledby="promo-editor-label"
    />
  );
};

const DisabledPlaceholder = () => <span>Promo already selected</span>;

interface PlaceholderProps {
  numPromosAvailable: number;
}
const Placeholder = ({ numPromosAvailable }: PlaceholderProps) => {
  if (numPromosAvailable > 0) {
    return (
      <span id="promo-editor-label" className="bs-text-gray-700">
        Select Promo Code{" "}
        <span className="bs-text-orange">({numPromosAvailable} available)</span>
      </span>
    );
  } else {
    return <span>Select Promo Code ({numPromosAvailable} available)</span>;
  }
};

export default PromoEditor;
