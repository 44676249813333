import { keyBy } from "lodash";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { MultiValue } from "react-select";

interface Option {
  label: string;
  value: number | string;
  ref_cdt_name?: string;
}

interface Field {
  name: string;
  type:
    | "checkbox"
    | "date"
    | "email"
    | "phone"
    | "url"
    | "datetime"
    | "integer"
    | "select"
    | "text"
    | "textarea";
  pre_populated_value?: {
    type: "PREVIOUS_RECORD";
  };
  meta: {
    label?: string;
    placeholder?: string;
    required?: boolean;
    multiple?: boolean;
    options?: Option[];
  };
}

interface Grid {
  name: string;
  x: number;
  y: number;
  size?: number;
}

interface EncounterDetails {
  type: "edit" | "view" | "add" | "preview";
  title: string;
  grid: Grid[];
  fields: Field[];
}

interface Props {
  encounterDetailsForm: EncounterDetails;
  encounterDetails: any;
  targetUrl: string;
  authenticityToken: string;
  disabled: boolean;
}

const EncounterDetailsForm = ({
  encounterDetailsForm,
  encounterDetails,
  targetUrl,
  authenticityToken,
  disabled,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty, errors },
    control,
  } = useForm({ defaultValues: encounterDetails });

  const fieldsByName = useMemo(
    () => keyBy(encounterDetailsForm.fields, "name"),
    [encounterDetailsForm]
  );

  const onSubmit = async (
    disposition: any,
    status: "FINALIZED" | "CANCELLED"
  ) => {
    await fetch(targetUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": authenticityToken,
      },
      body: JSON.stringify({
        disposition,
        status,
      }),
    });
  };

  const handleSubmitComplete = async () => {
    await handleSubmit((data) => onSubmit(data, "FINALIZED"))();
    alert("Encounter completed");
    location.reload();
  };

  const handleSubmitCancel = async () => {
    if (!confirm("Are you sure you want to cancel this encounter?")) return;
    await handleSubmit((data) => onSubmit(data, "CANCELLED"))();
    alert("Encounter cancelled");
    location.reload();
  };

  // Renders an input based on the field type
  const renderInput = (field: Field) => {
    const rules = { required: field.meta.required };
    const registration = register(field.name, rules);

    if (
      field.type === "text" ||
      field.type === "date" ||
      field.type === "integer"
    ) {
      return (
        <input
          className="form-control"
          type={field.type}
          disabled={disabled}
          {...registration}
        />
      );
    }

    if (field.type === "textarea") {
      return (
        <textarea
          className="form-control"
          disabled={disabled}
          {...registration}
        />
      );
    }

    if (field.type === "select") {
      const options =
        field.meta.options?.map(({ label, value }) => ({ label, value })) || [];
      const isMulti = field.meta.multiple;

      return (
        <Controller
          control={control}
          name={field.name}
          rules={rules}
          render={({ field }) => (
            <Select
              isMulti={isMulti}
              options={options}
              isDisabled={disabled}
              {...field}
              onChange={(value) => {
                if (isMulti) {
                  field.onChange(
                    (value as MultiValue<Option>).map((v) => v.value)
                  );
                } else {
                  field.onChange((value as Option).value);
                }
              }}
              value={options.find((o) => o.value == field.value)}
            />
          )}
        />
      );
    }

    return (
      <input
        className="form-control"
        type={field.type}
        disabled={disabled}
        {...registration}
      />
    );
  };

  return (
    <>
      <form className="max-w-3xl">
        <h2>Encounter Details</h2>
        <ol className="pl-0 list-inside">
          {encounterDetailsForm.grid
            .map(({ name }) => fieldsByName[name])
            .map((field) => (
              <li className="p-3 mb-3 bg-gray-200 rounded-lg" key={field.name}>
                {field.meta?.required && (
                  <span className="float-right text-red-500">*</span>
                )}
                <label
                  dangerouslySetInnerHTML={{
                    __html: field.meta.label ?? "Unknown",
                  }}
                />
                {renderInput(field)}
                {errors[field.name]?.type == "required" && (
                  <small className="text-red-500">This field is required</small>
                )}
              </li>
            ))}
        </ol>
      </form>
      {!disabled && (
        <>
          <button
            disabled={!isDirty || !isValid || isSubmitting}
            className="mr-2 btn btn-primary"
            onClick={handleSubmitComplete}
          >
            {isSubmitting ? "Submitting..." : "Complete Encounter"}
          </button>
          <button
            className="btn btn-danger"
            disabled={!isDirty || !isValid || isSubmitting}
            onClick={handleSubmitCancel}
          >
            {isSubmitting ? "Submitting..." : "Cancel Encounter"}
          </button>
        </>
      )}
    </>
  );
};

export default EncounterDetailsForm;
