import React, { useEffect } from "react";

const NotesEditor = (props) => {
  const { billingNotes, url, attributeName, csrfToken } = props;
  const [formShowing, setFormShowing] = React.useState(false);

  const toggle = (e) => {
    e.preventDefault();
    var currentlyShowing = formShowing;
    setFormShowing(!currentlyShowing);
  };

  return (
    <>
      {!formShowing && (
        <>
          <div>{billingNotes}</div>
          <a href="#" onClick={toggle} className="toggler">
            <i className="fa fa-pencil-square-o"></i>
          </a>
        </>
      )}
      {formShowing && (
        <div>
          <form
            role="form"
            action={url}
            acceptCharset="UTF-8"
            data-remote="true"
            method="post"
          >
            <input type="hidden" name="_method" value="put" />
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <div className="form-group">
              <label className="sr-only" htmlFor="billing_notes">
                Billing notes
              </label>
              <textarea
                className="form-control"
                name={attributeName}
                id="billing_notes"
                defaultValue={billingNotes}
              />
            </div>
            <input
              type="submit"
              name="commit"
              value="Update notes"
              className="btn btn-secondary"
              data-disable-with="Update notes"
            />
            <a href="#" onClick={toggle} className="ml-4">
              <i className="fa fa-times"></i>
            </a>
          </form>
        </div>
      )}
    </>
  );
};

export default NotesEditor;
