// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap";
import "controllers";
import "@hotwired/turbo";
import "@hotwired/turbo-rails";

import "../helpers/configure_ahoy";
import "../helpers/sentry";
import "../helpers/datadog";

import * as ActiveStorage from "@rails/activestorage";
import { captureMessage } from "@sentry/react";
import LocalTime from "local-time";

import Brightline from "../helpers/dom";

LocalTime.start();

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("reef/initialConsultations");
require("@nathanvda/cocoon");

require("css/application.scss");

// ensure jQuery and co are globally available
// we could also use the webpack expose-loader for this if needed
window.$ = window.jQuery = $;
window.Rails = Rails;
window.Turbolinks = Turbolinks;

window.Brightline ||= {};
window.Brightline = { ...window.Brightline, ...Brightline };

Turbo.session.drive = false;
Turbolinks.setProgressBarDelay(200);

// We have some links that are not `target: _top` but don't return a turbo-frame.
// For now, let's catch them and report them so we can track them down.
//
// See https://github.com/hotwired/turbo/pull/863
document.addEventListener("turbo:frame-missing", function (event) {
  const message = `Frame missing when navigating to ${event.detail.response.url}`;
  console.warn(message, event);
  // Report to Sentry
  captureMessage(message);

  // Visit the response
  event.preventDefault();
  window.location = event.detail.response.url;
});

var stafferComponentRequireContext = require.context(
  "components/staffer",
  true
);

var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(stafferComponentRequireContext);

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

ActiveStorage.start();

// Hotfix to load Rails components within turbo frames
// See: https://github.com/reactjs/react-rails/issues/1103
ReactRailsUJS.handleEvent("turbo:frame-load", ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent("turbo:frame-render", ReactRailsUJS.handleUnmount);
