import React from "react";
import Select from "react-select";

/*
  NOTE: This component is intended to be used inside a Rails form to
        replace a single select box with a Typeahead using react_component.
        It is not intended to be used inside other React components at the moment.
*/
export const TypeaheadField = ({
  name,
  options,
  value = undefined,
  maxMenuHeight = 500,
}: TypeaheadFieldProps) => {
  return (
    <Select
      name={name}
      options={options}
      maxMenuHeight={maxMenuHeight}
      defaultValue={value}
    />
  );
};

export interface TypeaheadFieldProps {
  name: string;
  options: any; // not sure how to type this correctly
  value?: any; // should be tied to the options type
  maxMenuHeight?: number;
}

export default TypeaheadField;
