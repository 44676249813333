const useFetchRequest = (csrfToken) => {
  const postRequest = async (url, data) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response;
  };

  return { postRequest };
};

export default useFetchRequest;
