import { groupBy } from "lodash";
import React, { useState } from "react";
import Select from "react-select";

interface Reminder {
  group: string;
  reminderTypeId: string;
  title: string;
  priority: string;
  notes: string;
  dueDate: string;
}

interface ReminderType {
  id: string;
  group: string;
  title: string;
  category: string;
  mode: string;
  priority: string;
}

interface Props {
  reminder: Reminder;
  reminderTypes: ReminderType[];
  groups: any[];
  priorities: any[];
  careCaseId: string;
  memberId: string;
  labelClasses: string;
}

export const ReminderFields = ({
  reminder,
  reminderTypes,
  groups,
  priorities,
  careCaseId,
  memberId,
  labelClasses,
}: Props) => {
  const [reminderType, setReminderType] = useState<string | null>(
    reminder.reminderTypeId
  );
  const [group, setGroup] = useState<string | null>(reminder.group);
  const [title, setTitle] = useState(reminder.title);
  const [priority, setPriority] = useState(reminder.priority);

  const filteredReminderTypes = reminderTypes.filter(
    (type) => type.group == group
  );

  const reminderTypeOptions = Object.entries(
    groupBy(filteredReminderTypes, "category")
  ).map(([category, reminderTypes]) => ({
    label: category,
    options: reminderTypes.map(({ id, title }) => ({
      value: id,
      label: title,
    })),
  }));

  const handleGroupChange = (newValue: any) => {
    setReminderType(null);
    setGroup(newValue);
  };

  const handleReminderTypeChange = (newValue: any) => {
    const reminderType = reminderTypes.find(({ id }) => id == newValue.value);

    if (reminderType) {
      setReminderType(reminderType.id);
      setTitle(reminderType.title);
      setPriority(reminderType.priority);
    } else {
      setReminderType(null);
    }
  };

  const selectedReminderType = reminderTypes.find(
    (option) => option.id == reminderType
  );

  const reminderTypeValue = selectedReminderType
    ? { value: selectedReminderType.id, label: selectedReminderType.title }
    : null;

  return (
    <div>
      <input type="hidden" name="reminder[care_case_id]" value={careCaseId} />
      <input type="hidden" name="reminder[member_id]" value={memberId} />
      <div className="row">
        <div className="form-group col-md">
          <label htmlFor="reminder[group]" className={labelClasses}>
            Group
          </label>
          <Select
            required
            id="reminder[group]"
            name="reminder[group]"
            classNamePrefix={"reminder-group"}
            options={groups}
            placeholder="Select a group"
            onChange={(newValue) => handleGroupChange(newValue.value)}
            value={groups.find((option) => option.value == group)}
            aria-labelledby="reminder[group]"
          />
        </div>
        <div className="form-group col-md">
          <label htmlFor="reminder[reminder_type_id]" className={labelClasses}>
            Reminder Type
          </label>
          <Select
            placeholder={
              group == null ? "Select a group first" : "Select a reminder type"
            }
            id="reminder[reminder_type_id]"
            name="reminder[reminder_type_id]"
            options={reminderTypeOptions}
            onChange={(newValue) =>
              newValue && handleReminderTypeChange(newValue)
            }
            value={reminderTypeValue}
            aria-labelledby="reminder[reminder_type_id]"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="reminder[title]" className={labelClasses}>
          Title
        </label>
        <input
          required
          type="text"
          id="reminder[title]"
          name="reminder[title]"
          className="form-control"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          aria-labelledby="reminder[title]"
        />
      </div>
      <div className="form-group">
        <label htmlFor="reminder[notes]" className={labelClasses}>
          Notes
        </label>
        <textarea
          id="reminder[notes]"
          name="reminder[notes]"
          className="form-control"
          defaultValue={reminder.notes}
          aria-labelledby="reminder[notes]"
        />
      </div>
      <div className="row">
        <div className="form-group col-md">
          <label htmlFor="reminder[priority]" className={labelClasses}>
            Priority
          </label>
          <Select
            required
            placeholder="Select a priority"
            id="reminder[priority]"
            name="reminder[priority]"
            classNamePrefix={"reminder-priority"}
            options={priorities}
            onInputChange={setPriority}
            value={priorities.find((option) => option.value == priority)}
            aria-labelledby="reminder[priority]"
          />
        </div>
        <div className="form-group col-md">
          <label htmlFor="reminder[due_date]" className={labelClasses}>
            Due date
          </label>
          <input
            required
            type="date"
            id="reminder[due_date]"
            name="reminder[due_date]"
            className="form-control"
            defaultValue={reminder.dueDate}
            aria-labelledby="reminder[due_date]"
          />
        </div>
      </div>
    </div>
  );
};

export default ReminderFields;
