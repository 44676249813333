import React from "react";

import BodyMapExercise from "../member/Exercises/BodyMap/BodyMapExercise";
import { BodyMapContext } from "../member/Exercises/BodyMap/Context";
import { ExerciseFormContext } from "../member/Exercises/shared/Context";

const BodyMapResult = (props) => {
  return (
    <BodyMapContext.Provider value={{ blobs: props.blobs }}>
      <ExerciseFormContext.Provider value={{ readOnly: true }}>
        <div className="bs-text-lightBrown">
          <BodyMapExercise />
        </div>
      </ExerciseFormContext.Provider>
    </BodyMapContext.Provider>
  );
};

export default BodyMapResult;
