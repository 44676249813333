import { Liquid } from "liquidjs";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { MultiValue } from "react-select";
const engine = new Liquid();

interface Field {
  label: string;
  hasInput: boolean;
  fieldName: string;
  meta: {
    type: string;
    required: boolean;
  };
}

interface Section {
  title: string;
  name: string;
  fields: Field[];
}

type ElementType = "FIELD" | "READ_ONLY";
type Action = "SHOW" | "HIDE";
type Operation = "AND" | "OR";

type ConditionType = "CONTAINS" | "EQUAL" | "NOT_EQUAL";

interface Condition {
  type: ConditionType;
  refCdtName: string;
  refCdtFieldName: string;
  name?: string;
  fieldName: string;
  value: number | string | string[];
}

interface Element {
  type: ElementType;
  action: Action;
  operation: Operation;
  refCdtName?: string;
  refCdtFieldName?: string;
  name?: string;
  fieldName: string;
  conditions: Condition[];
}

interface ConditionSettings {
  elements: Element[];
}

interface CdtFieldOption {
  label: string;
  value: number | string;
  refCdtName?: string;
}

interface CdtField {
  name: string;
  type:
    | "checkbox"
    | "date"
    | "datetime"
    | "email"
    | "file"
    | "integer"
    | "object"
    | "phone"
    | "select"
    | "text"
    | "textarea"
    | "url"
    | "uuid";
  editable: boolean;
  disable: boolean;
  displayType?: "CHECKBOX" | "RADIO" | "SELECT";
  valueType?: "INTEGER" | "STRING" | "OBJECT";
  customFieldType?: string;

  meta: {
    required?: boolean;
    multiple?: boolean;
    options?: CdtFieldOption[];
  };
}

interface Cdt {
  name: string;
  type: "MULTI_RECORD" | "SINGLE_RECORD";
  fields: CdtField[];
}

interface Props {
  targetUrl: string;
  authenticityToken: string;
  assessmentForm: {
    name: string;
    title: string;
    sections: Section[];
    conditionSettings: ConditionSettings;
  };
  assessment: any;
  cdt: Cdt;
  reefCdt: any;
  disabled: boolean;
}

const AssessmentForm = ({
  assessmentForm,
  assessment,
  cdt,
  targetUrl,
  authenticityToken,
  reefCdt,
  disabled,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty, errors },
    watch,
    control,
    getValues,
  } = useForm({ defaultValues: assessment });
  const [savingDraft, setSavingDraft] = React.useState(false);

  const onSubmit = async (
    answers: any,
    status: "COMPLETED" | "IN_PROGRESS"
  ) => {
    const response = await fetch(targetUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": authenticityToken,
      },
      body: JSON.stringify({
        name: assessmentForm.name,
        status,
        answers,
      }),
    });

    const body = await response.json();

    console.log("Success", body);
  };

  const handleSubmitDraft = async () => {
    setSavingDraft(true);
    await onSubmit(getValues(), "IN_PROGRESS");
    setSavingDraft(false);
    alert("Draft saved");
  };

  const handleSubmitCompleted = async () => {
    await handleSubmit((data) => onSubmit(data, "COMPLETED"))();
    alert("Assessment completed");
  };

  const cdtFieldsByName = useMemo(
    () => keyBy(cdt.fields, "name"),
    [cdt.fields]
  );
  const conditionElements = assessmentForm.conditionSettings.elements;
  const conditionElementsByFieldName = useMemo(
    () => keyBy(conditionElements, "fieldName"),
    [conditionElements]
  );

  const fieldValues = watch();

  const generateVisibilityMapping = () =>
    assessmentForm.sections.reduce(
      (acc: Record<string, boolean>, section: Section) => {
        acc[section.name] = isVisible(section.name);

        return section.fields.reduce(
          (acc: Record<string, boolean>, field: Field) => {
            acc[field.fieldName] = isVisible(field.fieldName);
            return acc;
          },
          acc
        );
      },
      {}
    );

  const injectCdt = (str: string) =>
    isCdtInjectible(str)
      ? engine.parseAndRenderSync(stripCdtPath(str), reefCdt)
      : str;
  const isCdtInjectible = (str: string) => str.includes("{{CDT");
  // Remove the ends of key path
  // {{CDT.caregiver_info.first_name.LAST_RECORD}}
  const stripCdtPath = (str: string) =>
    str.replace(/CDT\.|\.LAST_RECORD/gm, "");
  const replaceNewLine = (str: string) => str.replace(/\n/gm, "<br />");

  const isVisible = (fieldName: string) => {
    const conditionElement = conditionElementsByFieldName[fieldName];

    // If there are no conditions attached to a field, default to show
    if (!conditionElement) return true;

    const matchingConditions = conditionElement.conditions.filter(
      (condition: Condition) => {
        if (condition.type === "EQUAL") {
          return condition.value === fieldValues[condition.fieldName];
        } else if (condition.type === "NOT_EQUAL") {
          return condition.value !== fieldValues[condition.fieldName];
        } else if (condition.type === "CONTAINS") {
          const conditionValues = condition.value as string[];
          const thisFieldValues = Array.isArray(
            fieldValues[condition.fieldName]
          )
            ? fieldValues[condition.fieldName]
            : [];
          return conditionValues.some((value) =>
            thisFieldValues.includes(value)
          );
        } else {
          console.log("unhandled type", condition);
        }
      }
    );

    if (matchingConditions.length > 0) {
      const meetsCondition =
        conditionElement.operation === "AND"
          ? matchingConditions.length === conditionElement.conditions.length
          : true; // OR
      return conditionElement.action === "SHOW"
        ? meetsCondition
        : !meetsCondition; // HIDE
    }

    return false;
  };

  const visibilityMapping = useMemo(
    () => generateVisibilityMapping(),
    [fieldValues]
  );
  const visibleSections = (sections: Section[]) =>
    sections.filter((section) => visibilityMapping[section.name]);
  const visibleFields = (fields: Field[]) =>
    fields.filter((field) => visibilityMapping[field.fieldName]);

  const renderField = (field: Field) => {
    const label = replaceNewLine(injectCdt(field.label));

    if (!field.hasInput) {
      return (
        <div
          key={field.label}
          dangerouslySetInnerHTML={{ __html: field.label }}
        />
      );
    }

    return (
      <li className="p-3 mb-3 bg-gray-200 rounded-lg" key={field.fieldName}>
        {field.meta.required && (
          <span className="float-right text-red-500">*</span>
        )}
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {renderInput(field)}
        {errors[field.fieldName]?.type == "required" && (
          <small className="text-red-500">This field is required</small>
        )}
      </li>
    );
  };

  // Renders an input based on the field type
  const renderInput = (field: Field) => {
    const cdtField = cdtFieldsByName[field.fieldName];
    const rules = { required: cdtField.meta.required };
    const registration = register(field.fieldName, rules);

    if (
      cdtField.type === "text" ||
      cdtField.type === "date" ||
      cdtField.type === "integer"
    ) {
      return (
        <input
          className="form-control"
          type={cdtField.type}
          disabled={disabled}
          {...registration}
        />
      );
    }

    if (cdtField.type === "textarea") {
      return (
        <textarea
          className="form-control"
          disabled={disabled}
          {...registration}
        />
      );
    }

    if (cdtField.displayType === "CHECKBOX" || cdtField.type === "checkbox") {
      const options =
        cdtField.meta.options?.map(({ label, value }) => ({ label, value })) ||
        [];

      return (
        <div className="form-check">
          {options.map((option) => (
            <div key={option.value}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={option.value}
                  disabled={disabled}
                  {...registration}
                />
                {option.label}
              </label>
            </div>
          ))}
        </div>
      );
    }

    if (cdtField.displayType === "RADIO") {
      const options =
        cdtField.meta.options?.map(({ label, value }) => ({ label, value })) ||
        [];

      return (
        <div className="form-check">
          {options.map((option) => (
            <div key={option.value}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value={option.value}
                  disabled={disabled}
                  {...registration}
                />
                {option.label}
              </label>
            </div>
          ))}
        </div>
      );
    }

    if (cdtField.type === "select") {
      const options =
        cdtField.meta.options?.map(({ label, value }) => ({ label, value })) ||
        [];
      const isMulti = cdtField.meta.multiple;

      return (
        <Controller
          control={control}
          name={field.fieldName}
          rules={rules}
          render={({ field }) => (
            <Select
              isMulti={isMulti}
              options={options}
              isDisabled={disabled}
              {...field}
              onChange={(value) => {
                if (isMulti) {
                  field.onChange(
                    (value as MultiValue<CdtFieldOption>).map((v) => v.value)
                  );
                } else {
                  field.onChange((value as CdtFieldOption).value);
                }
              }}
              value={options.find((o) => o.value == field.value)}
            />
          )}
        />
      );
    }

    return (
      <input
        className="form-control"
        type={cdtField.type}
        disabled={disabled}
        {...registration}
      />
    );
  };

  return (
    <>
      <form className="max-w-3xl">
        <h2>{assessmentForm.title}</h2>
        <ol className="pl-0 list-inside">
          {visibleSections(assessmentForm.sections).map((section, index) => (
            <div key={index}>
              <h3>{section.title}</h3>
              {visibleFields(section.fields).map(renderField)}
            </div>
          ))}
        </ol>
      </form>
      {!disabled && (
        <>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
            onClick={handleSubmitCompleted}
          >
            {isSubmitting ? "Submitting..." : "Complete"}
          </button>

          <button
            className="btn btn-text"
            type="submit"
            disabled={!isDirty || isSubmitting || savingDraft}
            onClick={handleSubmitDraft}
          >
            {isSubmitting || savingDraft ? "Saving..." : "Save draft"}
          </button>
        </>
      )}
    </>
  );
};

export default AssessmentForm;
