import "@sendbird/uikit-react/dist/index.css";
import "@js/css/ChatApp.scss";

import {
  linkifySendbirdMessages,
  rewriteSendbirdAttachmentLinks,
} from "@js/helpers/sendbirdLinks";
import BrightlineIcon from "@js/images/branding/icons/Marigold.png";
import App from "@sendbird/uikit-react/App";
import React, { useEffect } from "react";

const ChatApp = (props: ChatProps) => {
  const { env, currentChatParticipant } = props;
  if (!currentChatParticipant) {
    return <div>Loading...</div>;
  }
  const { appId, logLevel } = env.sendbird;
  const { id: userId, name, sessionToken } = currentChatParticipant;

  useEffect(() => {
    const monitorLinks = setInterval(linkifySendbirdMessages, 500);

    return () => {
      clearInterval(monitorLinks);
    };
  });

  useEffect(() => {
    const monitorAttachments = setInterval(rewriteSendbirdAttachmentLinks, 500);
    return () => {
      clearInterval(monitorAttachments);
    };
  });

  return (
    <App
      appId={appId}
      userId={userId}
      nickname={name}
      accessToken={sessionToken}
      profileUrl={`${BrightlineIcon}`}
      config={{ logLevel }}
    ></App>
  );
};

export default ChatApp;

export interface ChatProps {
  currentChatParticipant: ChatParticipant;
  env: ChatEnv;
}

interface ChatParticipant {
  id: string;
  name: string;
  sessionToken: string;
}

export interface ChatEnv {
  sendbird: {
    appId: string;
    logLevel: string;
  };
}
