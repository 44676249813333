import React, { useEffect, useState } from "react";

interface CopyButtonProps {
  text: string;
}

export default function CopyButton(props: CopyButtonProps) {
  const [copySuccess, setCopySuccess] = useState("");

  function copyToClipboard(e) {
    const el = document.createElement("textarea");
    el.value = props.text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setCopySuccess("Copied!");
  }

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess("");
    }, 1500);
  }, [copySuccess]);

  return (
    <div className="d-flex flex-column">
      {document.queryCommandSupported("copy") && (
        <>
          <div className="input-group">
            <div
              className="cursor-pointer input-group-append"
              onClick={copyToClipboard}
            >
              <span className="input-group-text">
                <i className="fas fa-copy fa-fw"></i>
                Copy
              </span>
            </div>
            <div className="text-left w-100">
              <small className="text-success">{copySuccess}</small>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
