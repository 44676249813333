// import { isQueryCommandSupported } from "@js/helpers/document";
import React, { useEffect, useRef, useState } from "react";

interface ClipboardCopierProps {
  link: string;
}

export default function ClipboardCopier(props: ClipboardCopierProps) {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
  }

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess("");
    }, 1500);
  }, [copySuccess]);

  return (
    <div className="d-flex flex-column">
      {document.queryCommandSupported("copy") && (
        <>
          <div className="input-group">
            <input
              type="text"
              ref={textAreaRef}
              className="form-control"
              value={props.link}
              placeholder="Some path"
              id="copy-input"
              readOnly
              style={{ maxWidth: "150px" }}
            />
            <div
              className="cursor-pointer input-group-append"
              onClick={copyToClipboard}
            >
              <span className="input-group-text">
                <i className="fas fa-copy fa-fw"></i>
                Copy
              </span>
            </div>
          </div>
          <div className="text-right w-100">
            <small className="text-success">{copySuccess}</small>
          </div>
        </>
      )}
    </div>
  );
}
