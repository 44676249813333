function syncChildName() {
  const childNameInput = document.querySelector(
    `[data-sync-source="child-name"]`
  );

  if (childNameInput) {
    const childNameEls = document.querySelectorAll(
      `[data-sync-target="child-name"]`
    );
    // sync fields at the start based on the initial child name from onboarding
    // defaults to "your child"
    childNameEls.forEach((el) => (el.textContent = childNameInput.value));

    childNameInput.addEventListener("keyup", (e) => {
      childNameEls.forEach((el) => (el.textContent = e.target.value));
    });
  }
}

// TurboToDo: Convert this to turbo:load
document.addEventListener("turbolinks:load", () => {
  syncChildName();
});
